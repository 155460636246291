import { VStack, Flex, Stack, Text, Link } from '@chakra-ui/react';
import FormTitle from 'components/layouts/FormTitle';

const INFO_LIST = [
  {
    title: '活動時間',
    content: '第二屆 UBC 實體結業式',
  },
  {
    title: '日期時間',
    content: '2023/7/2 (日) 12:00-17:30',
  },
  {
    title: 'C-LAB 102 共享吧',
    content: '台北市大安區建國南路一段177號',
    map: 'https://goo.gl/maps/SnKs1mY1Ky8pTBgz8',
  },
  {
    title: '名額上限',
    content: '100 位',
  },
];

const InfoList = props => {
  const { title, content, map } = props;
  return (
    <Stack textStyle="content02" direction={{ base: 'column', md: 'row' }}>
      <Text fontWeight="700">{title}:</Text>
      <Text>{content}</Text>
      {map && (
        <Link color="brand-color" href={map}>
          Google Maps
        </Link>
      )}
    </Stack>
  );
};

const InfoSection = () => {
  return (
    <VStack w="100%" align="flex-start" spacing="40px">
      <FormTitle>活動資訊</FormTitle>
      <VStack align="flex-start" spacing="20px">
        {INFO_LIST.map(info => (
          <InfoList key={info.title} {...info} />
        ))}
      </VStack>
    </VStack>
  );
};

export default InfoSection;
