import Header from 'components/header/Header';
import Hero from 'sections/Hero';
import ValuesSection from 'sections/ValuesSection';
import AchievementsSection from 'sections/AchievementsSection';
import HowWeWorkSection from 'sections/HowWeWorkSection';
import WhatYouWillLearnSection from 'sections/WhatYouWillLearnSection';
import TimetableSection from 'sections/TimetableSection';
import CharitySection from 'sections/CharitySection';
import MentorSection from 'sections/MentorSection';
import SupportsSection from 'sections/SupportsSection';
import Footer from 'sections/Footer';
import TopStickyCallToAction from 'components/TopStickyCallToAction';

export function HomePage() {
  return (
    <>
      {/* <TopStickyCallToAction /> */}
      <Header />
      <Hero />
      <ValuesSection />
      <AchievementsSection />
      <HowWeWorkSection />
      <WhatYouWillLearnSection />
      <TimetableSection />
      <CharitySection />
      {/* <MentorSection /> */}
      <SupportsSection />
      <Footer />
    </>
  );
}
