import axios from 'axios';
import { useForm } from 'react-hook-form';
import { usePlaceOrder } from 'service/placeOrder';
import {
  Flex,
  Heading,
  Input,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useToast,
  Image,
  VStack,
  Text,
  Select,
  RadioGroup,
  Radio,
  HStack,
} from '@chakra-ui/react';

import ticketImage from 'assets/images/ticketImage.png';

const serviceURL = process.env.REACT_APP_API_URL;

const Form = () => {
  const {
    handleSubmit,
    register,
    // reset,
    formState: { errors, isSubmitting },
  } = useForm();

  const onSubmit = async formData => {
    console.log('submit', formData);
    const data = await fetch(`${serviceURL}/api/place-order`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
      },
      body: JSON.stringify({
        phone: formData.phone,
        name: formData.name,
        email: formData.email,
        options: formData.option,
        productName: 'UBC 實體結業式許可',
        productDescription: 'UBC 實體結業式許可',
        productQuantity: 1,
        paymentMethod: formData.payment,
        paymentInstallment: 0,
      }),
    }).then(response => response.json());
    const html = data.html;
    document.write(html);
  };

  return (
    <Flex
      w={{ base: '100%', lg: '420px' }}
      mb={{ base: '40px', lg: '0' }}
      h="100%"
      justify="center"
      align="flex-start"
      top="20px"
    >
      <Flex
        position="sticky"
        top="40px"
        border="1px solid blue"
        borderColor="bg-secondary"
        rounded="lg"
        overflow="hidden"
        direction="column"
      >
        <Image src={ticketImage} />
        <Flex w="100%" p="20px">
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <VStack w="100%" spacing="20px">
              <FormControl htmlFor="name" isInvalid={errors.name} maxW="500px">
                <FormLabel htmlFor="name">
                  <Text textStyle="text01">姓名*</Text>
                </FormLabel>
                <Input
                  placeholder="請輸入姓名"
                  {...register('name', {
                    required: 'This is required',
                  })}
                  py="24px"
                  fontSize="20px"
                  borderColor="black"
                />
                <FormErrorMessage>
                  {errors.name && errors.name.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                htmlFor="email"
                isInvalid={errors.email}
                maxW="500px"
              >
                <FormLabel htmlFor="email">
                  <Text textStyle="text01">信箱*</Text>
                </FormLabel>
                <Input
                  placeholder="your-email@email.com"
                  {...register('email', {
                    required: 'This is required',
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: 'invalid email address',
                    },
                  })}
                  py="24px"
                  fontSize="20px"
                  borderColor="black"
                />
                <FormErrorMessage>
                  {errors.email && errors.email.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl
                htmlFor="phone"
                isInvalid={errors.phone}
                maxW="500px"
              >
                <FormLabel htmlFor="phone">
                  <Text textStyle="text01">手機*</Text>
                </FormLabel>
                <Input
                  placeholder="09********"
                  {...register('phone', {
                    required: 'This is required',
                    pattern: {
                      value: /^[0-9]{10}$/,
                      message: 'invalid phone number',
                    },
                  })}
                  py="24px"
                  type="number"
                  fontSize="20px"
                  borderColor="black"
                />
                <FormErrorMessage>
                  {errors.phone && errors.phone.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.payment}>
                <FormLabel>付款方式</FormLabel>
                <Select
                  {...register('payment', {
                    required: 'This is required',
                  })}
                >
                  <option
                    value="creditCard"
                    style={{
                      color: 'white',
                      background: 'black',
                    }}
                  >
                    信用卡
                  </option>
                  {/* <option
                    value="CVS"
                    style={{
                      color: 'white',
                      background: 'black',
                    }}
                  >
                    超商代碼繳費
                  </option> */}
                  <option
                    value="ATM"
                    style={{
                      color: 'white',
                      background: 'black',
                    }}
                  >
                    ATM轉帳
                  </option>
                </Select>
                <FormErrorMessage>
                  {errors.service && errors.service.message}
                </FormErrorMessage>
              </FormControl>
              <FormControl isInvalid={errors.option}>
                <FormLabel>您是否為第二屆 UBC 學員及旁聽生*</FormLabel>
                <RadioGroup
                  {...register('option', {
                    // required: 'This is required',
                  })}
                  defaultValue="isStudent=true"
                >
                  <HStack spacing="24px">
                    <Radio colorScheme="orange" value="isStudent=true">
                      是
                    </Radio>
                    <Radio colorScheme="orange" value="isStudent=false">
                      否
                    </Radio>
                  </HStack>
                </RadioGroup>
                <FormErrorMessage>
                  {errors.option && errors.option.message}
                </FormErrorMessage>
              </FormControl>
              <VStack w="100%" align="flex-start" spacing="2px">
                <Text fontSize="14px">張數</Text>
                <Text textStyle="content02">1(每人限購一張)</Text>
              </VStack>
              <VStack w="100%" align="flex-start" spacing="2px">
                <Text fontSize="14px">金額</Text>
                <Text textStyle="content01">NT$ 300</Text>
              </VStack>
            </VStack>
            <Button
              isLoading={isSubmitting}
              rounded="md"
              type="submit"
              bg="brand-color"
              color="white"
              _hover={{ bg: 'orange.600' }}
              mt="10px"
              fontSize="20px"
              py="22px"
              h="60px"
              px="24px"
              w="100%"
            >
              購票
            </Button>
            <Text
              color="gray.600"
              textStyle="content03"
              mt="20px"
              textAlign="center"
            >
              下一步將連至藍新金流 Newebpay
              第三方金流平台，您所有的交易資訊皆獲得安全保護。
            </Text>
          </form>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default Form;
