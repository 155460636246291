import { useParams } from 'react-router-dom';
import { Flex, VStack } from '@chakra-ui/react';

import Header from 'components/header/Header';
import QaSection from 'sections/event/QaSection';
import Footer from 'sections/Footer';

export function FailPage() {
  const { orderId } = useParams();
  const error = new URLSearchParams(window.location.search).get('error');
  return (
    <>
      <Header />

      <Flex w="100%" py="15vh" justify="center">
        <Flex
          direction="column"
          w={{ base: '100%', lg: '80%' }}
          px="24px"
          maxW="1200px"
        >
          <Flex>訂單失敗</Flex>
          <Flex>訂單編號：{orderId}</Flex>
          <Flex mb="50px">錯誤訊息: {error}</Flex>
          <QaSection />
        </Flex>
      </Flex>
      <Footer />
    </>
  );
}
