import { VStack, Flex, Stack, Text, Link } from '@chakra-ui/react';
import FormTitle from 'components/layouts/FormTitle';

const AGENDA_LIST = [
  {
    time: '12:00 - 12:30',
    title: '參加者報到、自由交流',
  },
  {
    time: '12:30 - 13:00',
    title: '活動開場、公益成果展示',
  },
  {
    time: '13:00 - 13:30',
    title: '飛行導師回饋',
  },
  {
    time: '13:30 - 14:00',
    title: '如何從 UBCer 變成 UXer - 本丸',
    isMain: true,
  },
  {
    time: '14:00 - 14:10',
    title: 'QA Time',
  },
  {
    time: '14:10 - 14:40',
    title: '行銷 X 商業模式 - Tiraspace',
    isMain: true,
  },
  {
    time: '14:40 - 14:50',
    title: 'QA Time',
  },
  {
    time: '14:50 - 15:10',
    title: '休息與自由交流時間',
  },
  {
    time: '15:10 - 15:40',
    title: '從 0 開始經營個人品牌 - Portaly',
    isMain: true,
  },
  {
    time: '15:40 - 15:50',
    title: 'QA Time',
  },
  {
    time: '15:50 - 16:10',
    title: '區塊鏈證書如何成為專業的背書 - 圖靈證書',
    isMain: true,
  },
  {
    time: '16:10 - 16:20',
    title: 'QA Time',
  },
  {
    time: '16:20 - 16:40',
    title: '抽獎活動、活動閉幕',
  },
  {
    time: '16:40 - 17:30',
    title: '自由交流時間',
  },
];

const AgendaItem = props => {
  const { time, title, isMain } = props;
  return (
    <Stack textStyle="content02" direction={{ base: 'column', md: 'row' }}>
      <Text>{time}:</Text>
      <Text fontWeight={isMain ? 700 : 400}>{title}</Text>
    </Stack>
  );
};

const AgendaSection = () => {
  return (
    <VStack w="100%" align="flex-start" spacing="40px">
      <FormTitle>流程簡介</FormTitle>
      <VStack align="flex-start" spacing="15px">
        {AGENDA_LIST.map(info => (
          <AgendaItem key={info.time} {...info} />
        ))}
      </VStack>
    </VStack>
  );
};

export default AgendaSection;
