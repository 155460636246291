import { useLocation } from 'react-router-dom';

import { Flex, VStack, Text } from '@chakra-ui/react';

import Header from 'components/header/Header';
import QaSection from 'sections/event/QaSection';
import Footer from 'sections/Footer';

export function OrderInfoPage() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const bankCode = queryParams.get('bankCode');
  const bankAccount = queryParams.get('bankAccount');
  const cvsPaymentNo = queryParams.get('cvsPaymentNo');

  return (
    <>
      <Header />
      <Flex w="100%" py="15vh" justify="center">
        <Flex
          direction="column"
          w={{ base: '100%', lg: '80%' }}
          px="24px"
          maxW="1200px"
          algin="flex-start"
        >
          <VStack spacing="32px" mb={{ base: '40px', lg: '100px' }}>
            <VStack spacing="0px">
              <Text textStyle="content02">感謝您的報名！</Text>
              <Text textStyle="heading04">訂單已成立 請儘速完成付款</Text>
            </VStack>
            <VStack spacing="0px">
              <Text textStyle="content02">
                請於七天內完成匯款，逾期將自動取消訂單！
              </Text>
              <Text textStyle="content02" color="brand-color">
                款項入帳後系統將寄送「付款交易結果通知信」至您的信箱
              </Text>
              <Text textStyle="content02">
                通知信為活動參與憑證，請至信箱檢查
              </Text>
            </VStack>
            {bankCode && (
              <VStack maxW="600px" align="flex-start" w="100%">
                <Text textAlign="left">ATM 匯款資訊</Text>
                <Text
                  textAlign="left"
                  textStyle="content01"
                  fontWeight="700"
                  color="brand-color"
                >
                  銀行代碼：{bankCode}
                </Text>
                <Text
                  textStyle="content01"
                  fontWeight="700"
                  color="brand-color"
                >
                  銀行帳號：{bankAccount}
                </Text>
                <Text color="gray.500">ATM 匯款資訊已同步寄至您的信箱</Text>
              </VStack>
            )}
            {cvsPaymentNo && (
              <VStack align="flex-start">
                <Text textAlign="left">超商匯款資訊</Text>
                <Text textAlign="left">超商繳款編號：{cvsPaymentNo}</Text>
                <Text>詳細資訊可以前往信箱檢查</Text>
              </VStack>
            )}
          </VStack>
          <QaSection />
        </Flex>
      </Flex>
      <Footer />
    </>
  );
}
