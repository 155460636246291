import {
  VStack,
  HStack,
  Stack,
  Text,
  Avatar,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionIcon,
  AccordionButton,
  Box,
} from '@chakra-ui/react';
import FormTitle from 'components/layouts/FormTitle';

const QA_LIST = [
  {
    question: '若無法參與實體活動，當天是否有線上直播？',
    answer:
      '第二屆 UBC 聯合讀書會結業式將在實體與線上同步舉行，活動當天將於 UX Book Club Taiwan Facebook 粉絲專頁（https://www.facebook.com/ubctaiwan/）進行直播，但如果想與來自不同領域和行業的人士進行交流，擴大自己人脈的話建議把握難得的實體交流場合。',
  },
  {
    question: '非第二屆 UBC 學員和旁聽生，是否也可以參加？',
    answer:
      '當然可以，我們歡迎所有對 UBC 聯合讀書會或者對活動講座有興趣的夥伴，一同參與屬於第二屆 UBC 聯合讀書會成員的榮耀時刻！',
  },
  {
    question: '為什麼報名成功後沒有收到通知？',
    answer:
      '建議可先至報名時填寫的 Email 收件夾中查詢一封由 **藍新金流 NewebPay** 發送的「付款交易通知信」，如果沒有看到信件可至「**垃圾郵件**」或「**促銷類別**」做進一步確認。活動開始前主辦單位將寄送【第二屆 UBC 聯合讀書會】實體結業式邀請函至您的報名信箱，活動當天請憑邀請函入場！',
  },
  {
    question: '報名成功後是否可以退款？',
    answer:
      '可以，但會酌收退款手續費。1.活動開始前一週（2023.06.25 23:59）為最後退款日，如因個人因素申請退款，將扣除 30% 退款手續費，剩餘款項退款至您的帳戶。2.活動開始一週內（2023.06.26 0:00～），因活動場地與餐點數量已確認，恕不接受退款，但可將門票轉讓給您朋友。',
  },
  {
    question: '活動經費運用方式？',
    answer:
      '活動費用只會用來支付場地租金、茶點費用、器材租金等結業式相關支出，如經費有餘款將捐贈給台灣城鄉永續關懷協會 - 小草書屋（https://www.grassbookhouse.org.tw/）。',
  },
  {
    question: '沒有找到想問的問題？',
    answer:
      '請寫信至請寄信至 uxbookclub.contact@gmail.com，主旨「 第二屆 UBC 聯合讀書會結業式｜ＯＯ問題」，或私訊 UX Book Club Taiwan Facebook 粉絲專頁（https://www.facebook.com/ubctaiwan/）將由團隊夥伴協助您！',
  },
];

const QaItem = props => {
  const { question, answer } = props;
  return (
    <>
      <AccordionItem w="100%">
        <Text>
          <AccordionButton>
            <Box as="span" flex="1" textAlign="left">
              {question}
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Text>
        <AccordionPanel pb={4} color="brand-color">
          {answer}
        </AccordionPanel>
      </AccordionItem>
    </>
  );
};

const QaSection = () => {
  return (
    <VStack w="100%" align="flex-start" spacing="20px">
      <FormTitle>常見問題</FormTitle>
      <Accordion w="100%" align="flex-start" spacing="25px">
        {QA_LIST.map(info => (
          <QaItem key={info.question} {...info} />
        ))}
      </Accordion>
    </VStack>
  );
};

export default QaSection;
