import { useParams } from 'react-router-dom';

import { Flex, Text, VStack } from '@chakra-ui/react';

import Header from 'components/header/Header';
import QaSection from 'sections/event/QaSection';
import Footer from 'sections/Footer';

export function SuccessPage() {
  const { orderId } = useParams();
  return (
    <>
      <Header />
      <Flex w="100%" py="15vh" justify="center">
        <Flex
          direction="column"
          w={{ base: '100%', lg: '80%' }}
          px="24px"
          maxW="1200px"
          pt={{ base: '80px', lg: '120px' }}
          align="center"
        >
          <VStack spacing="32px" mb={{ base: '40px', lg: '100px' }}>
            <VStack spacing="0px">
              <Text textStyle="content02">感謝您的報名！</Text>
              <Text textStyle="heading04">線上刷卡付款成功</Text>
            </VStack>
            <VStack spacing="0px">
              <Text textStyle="content02">
                「付款交易結果通知信」已寄送至您的信箱
              </Text>
              <Text textStyle="content02" color="brand-color">
                訂單編號：{orderId}
              </Text>
              <Text textStyle="content02">
                通知信為活動參與憑證，請至信箱檢查
              </Text>
            </VStack>
          </VStack>
          <Flex w="100%" maxW="900px">
            <QaSection />
          </Flex>
        </Flex>
      </Flex>
      <Footer />
    </>
  );
}
