import Header from 'components/header/HeaderLight';
import Footer from 'sections/Footer';
import { Flex, VStack } from '@chakra-ui/react';
import Form from 'sections/Form';
import BriefSection from 'sections/event/BriefSection';
import InfoSection from 'sections/event/InfoSection';
import AgendaSection from 'sections/event/AgendaSection';
import SpeakerSection from 'sections/event/SpeakerSection';
import QaSection from 'sections/event/QaSection';

export function EventPage() {
  return (
    <>
      <Header />
      <Flex w="100%" py="15vh" justify="center">
        <Flex
          w={{ base: '100%', lg: '80%' }}
          px="24px"
          maxW="1200px"
          direction={{ base: 'column-reverse', lg: 'row' }}
        >
          <VStack
            pr={{ base: '0', lg: '40px' }}
            spacing="80px"
            flex="1"
            align="flex-start"
          >
            <BriefSection />
            <InfoSection />
            <AgendaSection />
            <SpeakerSection />
            <QaSection />
          </VStack>
          <Form />
        </Flex>
      </Flex>
      <Footer />
    </>
  );
}
