import {
  Flex,
  Heading,
  Input,
  Button,
  FormErrorMessage,
  FormControl,
  FormLabel,
  useToast,
  VStack,
  HStack,
  Text,
  chakra,
} from '@chakra-ui/react';

const TAGS_LIST = ['活動', '人脈', '交流', '學習'];

const Tag = props => {
  return (
    <Text color="brand-color" textStyle="content02" fontWeight="700">
      #{props.children}
    </Text>
  );
};

const BriefSection = () => {
  return (
    <VStack align="flex-start">
      <VStack align="flex-start">
        <Heading textStyle="heading04" color="text-title">
          第二屆 UBC <chakra.br display={{ base: 'unset', md: 'none' }} />
          實體結業式
        </Heading>
        <HStack>
          {TAGS_LIST.map(tag => (
            <Tag key={tag}>{tag}</Tag>
          ))}
        </HStack>
      </VStack>
      <VStack spacing="40px">
        <Text textStyle="content02" color="text-title">
          第二屆 UBC 聯合讀書會結業式，旨在為 UBCer
          提供一個與業界專家和其他夥伴交流的平台，並藉由飛行導師與業界專家的分享更深入地了解最貼近業界的近況和趨勢。
        </Text>
        <Text textStyle="content02" color="text-title">
          此外，除了專業能力外，職場上更不可或缺的即戰力即是「人脈」，因此透過結業式活動、加強學員之間的交流和學習，建立人脈網，不僅增加工作上互助的好夥伴，更是為自己提高職場即戰力。
        </Text>
      </VStack>
    </VStack>
  );
};

export default BriefSection;
