import { VStack, HStack, Stack, Text, Avatar } from '@chakra-ui/react';
import FormTitle from 'components/layouts/FormTitle';
import benwan from 'assets/images/speaker/benwan.jpg';
import portaly from 'assets/images/speaker/portaly.jpg';
import turing from 'assets/images/speaker/turing.jpg';

const SPEAKER_LIST = [
  {
    avatar: benwan,
    name: '本丸',
  },
  {
    avatar: portaly,
    name: 'Portaly',
  },
  {
    avatar: turing,
    name: '圖靈證書',
  },
];

const SpeakerItem = props => {
  const { avatar, name } = props;
  return (
    <VStack textStyle="content02">
      <Avatar border="2px solid #EA593D" src={avatar} />
      <Text>{name}</Text>
    </VStack>
  );
};

const SpeakerSection = () => {
  return (
    <VStack w="100%" align="flex-start" spacing="40px">
      <FormTitle>參與講者</FormTitle>
      <HStack align="flex-start" spacing="25px">
        {SPEAKER_LIST.map(info => (
          <SpeakerItem key={info.name} {...info} />
        ))}
      </HStack>
    </VStack>
  );
};

export default SpeakerSection;
