import { useState, useEffect } from 'react';
import {
  Flex,
  Image,
  HStack,
  Link,
  Text,
  useColorMode,
} from '@chakra-ui/react';
import { Link as RouterLink } from 'react-router-dom';

import { motion } from 'framer-motion';

import ColorModeSwitcher from 'components/ColorModeSwitcher';
import LanguageSwitcher from 'components/LanguageSwitcher';
import UBCLogo from 'assets/images/UBCLogo.svg';
import UBCLogoDark from 'assets/images/UBCLogoDark.svg';
import { ArrowForwardIcon } from '@chakra-ui/icons';

const MotionFlex = motion(Flex);

const Header = () => {
  const [isShow, setIsShow] = useState(true);
  const [lastScrollY, setLastScrollY] = useState(0);

  const { colorMode } = useColorMode();

  useEffect(() => {
    const controlNavbar = () => {
      if (typeof window !== 'undefined') {
        if (window.scrollY > lastScrollY + 0.5 && window.scrollY > 80) {
          setIsShow(false);
        } else {
          setIsShow(true);
        }

        setLastScrollY(window.scrollY);
      }
    };

    if (typeof window !== 'undefined') {
      window.addEventListener('scroll', controlNavbar);

      return () => {
        window.removeEventListener('scroll', controlNavbar);
      };
    }
  }, [lastScrollY]);

  const isLight = colorMode === 'light';
  const variants = {
    open: { opacity: 1, y: 0 },
    closed: { opacity: 0, y: -80 },
  };
  return (
    <MotionFlex
      overflow="hidden"
      transition={{ duration: 0.5 }}
      style={{ originY: 0 }}
      animate={isShow ? 'open' : 'closed'}
      variants={variants}
      position="fixed"
      // h="200px"
      // bgColor="bg-secondary"
      // position="fixed"
      zIndex="20"
      w="100%"
      // h="80px"
      // justify="center"
      direction="column"
    >
      <Flex
        w="100%"
        bgColor="brand-color"
        h="56px"
        justify="center"
        align="center"
        px="20px"
      >
        <Flex justify="space-between">
          <HStack spacing="16px">
            <Text color="white">🎓 讀書會結業式活動報名</Text>
            <HStack spacing="10px" color="white" _hover={{ opacity: 0.8 }}>
              <Text as={RouterLink} to="/event">
                前往購票
              </Text>
              <ArrowForwardIcon />
            </HStack>
          </HStack>
        </Flex>
      </Flex>
      <Flex
        bgColor="bg-secondary"
        zIndex="20"
        w="100%"
        h="80px"
        justify="center"
      >
        <Flex
          w="120px"
          h="53px"
          mt="15px"
          position="relative"
          as={Link}
          href="/"
        >
          <Image
            top="0"
            position="absolute"
            w="100%"
            src={UBCLogo}
            zIndex={isLight && 3}
          />
          <Image position="absolute" w="100%" src={UBCLogoDark} zIndex="2" />
        </Flex>
      </Flex>
    </MotionFlex>
  );
};

export default Header;
